/** @jsxImportSource @emotion/react */

import { Avatar, Typography, theme } from "@a_team/ui-components";
import { css } from "@emotion/react";
import { ToBuilderLocation } from "Locations";
import BookInterviewButtonWrapper from "components/BookInterviewButton/BookInterviewButtonWrapper";
import { Flags } from "configs/featureFlags";
import { signForCurrency } from "helpers/currency";
import {
  formatYearsExperience,
  getGithubProfileUrl,
  getLinkedInProfileUrl,
  truncate,
} from "helpers/strings";
import useCanViewProposalRates from "hooks/access/useCanViewProposalRates";
import { useCSSRulesWithTheme } from "hooks/useCSSRulesWithTheme";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { ClientSignalActionType } from "models/ClientSignal";
import {
  ClientReviewStatus,
  ProposalRole,
  ProposalRoleBuilder,
} from "models/Proposal";
import { useClientSignalsForBuilder } from "queries/discover/useClientSignalsForBuilder";
import { useCallback, useMemo } from "react";
import { ReactSVG } from "react-svg";
import { Flex } from "views/Builder/components/Flex";
import { Markdown } from "views/Builder/components/Markdown";
import { TruncatedContent } from "views/Builder/components/TruncatedContent";
import { reformat } from "views/Builder/components/utils";
import { InterviewButton } from "views/Mission/Proposals/Native/InterviewButton";
import {
  MetaData,
  MetaDataComponent,
} from "views/Mission/Proposals/Native/MetaDataComponent";
import { RatesComponent } from "views/Mission/Proposals/Native/RatesComponent";
import { Skills } from "views/Mission/Proposals/Native/Skills";
import star from "./assets/star.svg";

export const FullBuilderCard = ({
  builder,
  currency,
  onSelectForInterview,
  role,
  enableInteractions = false,
  isSampleProposal,
  isLoggedOut,
  proposalId,
}: {
  builder: ProposalRoleBuilder;
  currency: "USD" | "EUR";
  onSelectForInterview: (builderId: string) => void;
  role: ProposalRole;
  enableInteractions: boolean;
  isSampleProposal?: boolean;
  isLoggedOut?: boolean;
  proposalId: string;
}) => {
  const { data: clientSignals } = useClientSignalsForBuilder(builder.uid);
  const showRates = useCanViewProposalRates();
  const { flagEnabled: monthlyRetainerRatesEnabled } = useFeatureFlag(
    Flags.MonthlyRetainerRates
  );

  const starred = useMemo(() => {
    if (!clientSignals) {
      return false;
    }

    return clientSignals.some(
      ({ actionType }) => actionType === ClientSignalActionType.Star
    );
  }, [clientSignals]);

  const selectForInterview = useCallback(() => {
    if (builder) {
      onSelectForInterview(builder.uid);
    }
  }, [builder]);

  const workingHoursOverlap = 0;

  const metaData = useMemo(() => {
    const tempMetaData: MetaData = [];

    if (
      builder?.location?.city &&
      builder?.location?.country &&
      builder?.location?.countryShortName
    ) {
      // Check if builder location country is longer than 12 characters, then use country short name instead
      tempMetaData.push([
        `${builder.location.city}, ${
          builder.location.country.length > 12
            ? builder.location.countryShortName
            : builder.location.country
        }`,
      ]);
    }

    if (
      ((showRates && !monthlyRetainerRatesEnabled) || isSampleProposal) &&
      builder.clientDisplayRate
    ) {
      tempMetaData.push([
        `${signForCurrency(currency)}${builder.clientDisplayRate}/hr`,
      ]);
    }

    if (builder.hoursAvailablePerWeek) {
      tempMetaData.push([`${builder.hoursAvailablePerWeek} hrs/week`]);
    }

    if (workingHoursOverlap && workingHoursOverlap > 0) {
      tempMetaData.push([`${workingHoursOverlap} hrs overlap`]);
    }

    if (builder?.yearsOfExperience || builder.yearsExperience) {
      tempMetaData.push([
        `${formatYearsExperience(
          builder.yearsOfExperience || builder.yearsExperience
        )} years exp`,
      ]);
    }

    return tempMetaData;
  }, [builder, currency, showRates, workingHoursOverlap]);

  const linksMetaData = useMemo(() => {
    const tempMetaData: MetaData = [];

    if (builder?.linkedin?.username) {
      tempMetaData.push([
        "LinkedIn",
        {
          link: getLinkedInProfileUrl(builder.linkedin.username),
        },
      ]);
    }

    if (builder?.github?.username) {
      tempMetaData.push([
        "Github",
        {
          link: getGithubProfileUrl(builder.github.username),
        },
      ]);
    }

    if (builder.tfsPitch?.website) {
      tempMetaData.push(["Website", { link: builder.tfsPitch.website }]);
    }

    if (builder?.websites && builder.websites.length > 0) {
      tempMetaData.push(["Website", { link: builder.websites[0] }]);
    }

    if (builder?.cvURL) {
      tempMetaData.push(["Resume", { link: builder.cvURL }]);
    }

    return tempMetaData;
  }, [builder, currency, showRates, workingHoursOverlap]);

  const isSelectedForInterview =
    (builder.clientReview || {}).status ===
    ClientReviewStatus.NotifiedForInterview;

  const backgroundColor = useMemo(() => {
    if (isSelectedForInterview) {
      return theme.colors.Green[100];
    }

    if (starred) {
      return theme.colors.Baracus[100];
    }

    return theme.colors.Grey[100];
  }, [isSelectedForInterview, starred]);

  const gotoProfile = () => {
    window.open(ToBuilderLocation(builder.uid), "_blank");
  };

  const styles = useCSSRulesWithTheme(({ breakpoints, colors }) => ({
    card: {
      backgroundColor: backgroundColor ?? colors.Grey[100],
      borderRadius: 16,
      display: "block",
      width: "auto",
      overflow: "hidden",
      flexGrow: 0,
      [`@media (min-width: ${breakpoints.sm}px)`]: {
        maxWidth: 560,
      },
    },
    starredHeader: {
      backgroundColor: "rgba(255, 233, 212, 0.50)",
      paddingBottom: 10,
      paddingLeft: 16,
      paddingRight: 12,
      paddingTop: 10,
      [`@media (min-width: ${breakpoints.sm}px)`]: {
        paddingBottom: 10,
        paddingLeft: 24,
        paddingRight: 12,
        paddingTop: 10,
      },
    },
    container: css({
      display: "flex",
      padding: 16,
      columnGap: 8,
      position: "relative",
      [`@media (min-width: ${breakpoints.sm}px)`]: {
        padding: 24,
      },
    }),
    content: {
      flex: 1,
    },
    avatar: {
      width: 72,
      height: 72,
      cursor: "pointer",
    },
    pt: {
      1: {
        paddingTop: 4,
      },
      2: {
        paddingTop: 8,
      },
      3: {
        paddingTop: 12,
      },
    },
    mt: {
      marginTop: 16,
    },
    sm: {
      display: "none",
      [`@media (min-width: ${breakpoints.sm}px)`]: {
        display: "block",
      },
      hide: {
        [`@media (min-width: ${breakpoints.sm}px)`]: {
          display: "none",
        },
      },
    },
    bookInterview: css({
      marginTop: 16,
      [`@media (min-width: ${breakpoints.sm}px)`]: {
        marginTop: 0,
        position: "absolute",
        top: 16,
        right: 16,
      },
    }),
    bookInterviewButton: {
      width: "100%",
      [`@media (min-width: ${breakpoints.sm}px)`]: {
        width: "auto",
      },
    },
  }));

  // Check if need to truncate blurb
  const blurbTruncateLimit = 450;
  const blurbText = isSampleProposal
    ? builder.blurb || builder.aboutMe
    : builder.tfsPitch?.blurb;
  const truncatedBlurb =
    blurbText && blurbText.length > blurbTruncateLimit ? (
      <Markdown>{truncate(blurbText ?? "", blurbTruncateLimit, "")}</Markdown>
    ) : undefined;

  return (
    <div css={[styles.card]} data-testing-id={"proposal-builder-card"}>
      {starred && (
        <div css={styles.starredHeader}>
          <Flex gap={4} justifyContent="flex-start">
            <ReactSVG src={star} />
            <Typography
              variant="textMedium"
              component="div"
              style={{
                paddingLeft: 8,
              }}
            >
              You shortlisted this profile previously
            </Typography>
          </Flex>
        </div>
      )}
      <div css={styles.container}>
        <div css={[styles.sm]}>
          <Avatar
            css={styles.avatar}
            src={reformat(builder.profilePictureURL, "-/preview/150x150/")}
            fill={true}
            size="xl"
            onClick={gotoProfile}
          />
        </div>
        <div css={styles.content}>
          <Flex gap={12} wrap="nowrap">
            <div css={[styles.sm.hide]}>
              <Avatar
                src={reformat(builder.profilePictureURL, "-/preview/300x300/")}
                fill={true}
                size="lg"
                onClick={gotoProfile}
              />
            </div>
            <div style={{ flex: 1 }}>
              <Flex
                justifyContent="space-between"
                wrap="nowrap"
                alignItems="flex-start"
                style={{
                  flex: 1,
                }}
              >
                <div>
                  <Typography
                    variant="textMedium"
                    component="div"
                    style={{
                      flexShrink: 0,
                    }}
                  >
                    {role.roleCategory?.title}
                  </Typography>
                  <Typography
                    variant="textLarge"
                    weight="bold"
                    style={{
                      flexShrink: 0,
                    }}
                  >
                    {builder.fullName}
                  </Typography>
                </div>
                {enableInteractions && !isSampleProposal && (
                  <div
                    css={[styles.sm]}
                    style={{
                      flexShrink: 0,
                    }}
                  >
                    <InterviewButton
                      builderId={builder.uid}
                      onClick={() => onSelectForInterview(builder.uid)}
                      selected={isSelectedForInterview}
                    />
                  </div>
                )}
              </Flex>
              <div css={[styles.sm, styles.pt[1]]}>
                <MetaDataComponent
                  proposalId={proposalId}
                  metaData={metaData}
                  source={"Builder Card"}
                  isSampleProposal={!!isSampleProposal}
                />
                <MetaDataComponent
                  proposalId={proposalId}
                  metaData={linksMetaData}
                  source={"Builder Card"}
                  isSampleProposal={!!isSampleProposal}
                />
              </div>
            </div>
          </Flex>
          <div css={[styles.sm.hide, styles.pt[3]]}>
            <MetaDataComponent
              proposalId={proposalId}
              metaData={metaData}
              source={"Builder Card"}
              isSampleProposal={!!isSampleProposal}
            />
            <MetaDataComponent
              proposalId={proposalId}
              metaData={linksMetaData}
              source={"Builder Card"}
              isSampleProposal={!!isSampleProposal}
            />
          </div>
          {monthlyRetainerRatesEnabled && showRates && !isSampleProposal && (
            <RatesComponent
              currency={currency}
              hourlyRate={
                builder.showHourlyRate ? builder.clientDisplayRate : undefined
              }
              monthlyRate={
                builder.showMonthlyRate
                  ? builder.clientMonthlyDisplayRate
                  : undefined
              }
            />
          )}
          <div css={[styles.pt[1]]}>
            <Typography variant="textMedium" component="div">
              <TruncatedContent
                expandComponent={(expand) => (
                  <Typography
                    color={"Hannibal@600"}
                    component={"span"}
                    onClick={() => expand()}
                    style={{ cursor: "pointer" }}
                    variant={"textMedium"}
                  >
                    {` More details`}
                  </Typography>
                )}
                truncateComponent={(collapse) => (
                  <Typography
                    color={"Hannibal@600"}
                    component="span"
                    onClick={() => collapse()}
                    style={{ cursor: "pointer" }}
                    variant={"textMedium"}
                  >
                    {` Close`}
                  </Typography>
                )}
                truncatedContent={truncatedBlurb}
              >
                <Markdown>{blurbText ?? ""}</Markdown>
              </TruncatedContent>
            </Typography>
          </div>
          {builder.skillsOverlap && (
            <div css={[styles.pt[3]]}>
              <Skills skills={builder.skillsOverlap} />
            </div>
          )}
          {enableInteractions && !isSampleProposal && (
            <div css={[styles.sm.hide, styles.mt]}>
              <InterviewButton
                builderId={builder.uid}
                onClick={selectForInterview}
                selected={isSelectedForInterview}
              />
            </div>
          )}
          {(isSampleProposal || isLoggedOut) && (
            <div css={styles.bookInterview}>
              <BookInterviewButtonWrapper
                userId={builder.uid}
                labelMobile="Book an interview"
                css={styles.bookInterviewButton}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
